    import Vue from 'vue'
    import App from './App.vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import './V2/filters/filters'
    import router from './V2/router'
    import store from './V2/store'
    import lodash from 'lodash';
    import vuetify from './V2/plugins/vuetify';
    import Print from "vue-print-nb"
    import VueTour from 'vue-tour'
    import 'vue-tour/dist/vue-tour.css';
    import orderTools from "@/V2/helpers/orderTools";

    Object.defineProperty(Vue.prototype, '$lodash', { value: lodash });

    Vue.use(VueAxios, axios)
    Vue.use(Print)
    Vue.use(VueTour)

    Vue.config.productionTip = false
    
    window.onerror = function (msg, url, line, col, error) {
        console.log('js error handler', error)
    }

    declare global {
        interface Window {
            app: any;
            fetchFunction: any;
        }
    }

    try {
//        orderTools.log_debug({message: 'Start', shop: window.location})
        let no_more_errors = false

        window.addEventListener('unhandledrejection', function(event) {
            if(!no_more_errors){
                try {
                    const err = {message: event.reason.message, stack: event.reason.stack}
                    orderTools.log_error(err)
                } catch (e) {
                    console.log(e)
                }
                no_more_errors = true
            }
        });

        new Vue({
            router: router,
            store: store,
            render: h => h(App),
            vuetify,
    
            created: async function () {
//                const app = await this.$store.dispatch('Shopify/initAppBridge', this.$route.query.host)
//                if(app) {
//                    this.$store.dispatch('init')
//                }
        }
        }).$mount('#app')

    } catch (e) {
        const err = {message: 'main.js error', stack: e}
        orderTools.log_debug(err)
//        orderTools.log_error(err)
    }
