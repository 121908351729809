<template>
    <div>
        
    </div>
</template>
  
<script>
    import { mapState } from 'vuex'
    import { getSessionToken } from '@shopify/app-bridge-utils';
    import { authenticatedFetch } from '@shopify/app-bridge-utils';
    import {graphql} from "@/V2/helpers/shopify";
    import { Redirect } from '@shopify/app-bridge/actions';
    import createApp from '@shopify/app-bridge';

    export default {
        name: 'Install',
        data: function () {
            return {
                app: null,
            }
        },
        methods: {
            checkBillingStatus: async function () {
                const query = `{
                    currentAppInstallation{
                        id
                        activeSubscriptions {
                            createdAt
                        }
                    }
                }`
                try {
                    await graphql({
                        data: {
                            graphql: {
                                query: query
                            }
                        }
                    }).then(response => {
                        if(response.data.data.currentAppInstallation.activeSubscriptions.length) {
                            this.$router.push('/init')
                        } else {
                            this.initBilling()
                        }
                    });
                    
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            },
            initBilling: async function () {
                const session_token = await getSessionToken(this.app);
                this.axios.post(`${this.env.api_server}/api/v3/billings/initiate.json`, {return_url: '/billing/activate'}, {
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${session_token}`,
                        'App': process.env.VUE_APP_APP_NAME,
                    }
                }).then(response => {
                    if(response.data.billing){
                        const redirect = Redirect.create(this.Shopify.app);
                        redirect.dispatch(Redirect.Action.REMOTE, {
                            url: response.data.billing.confirmation_url,
                            newContext:false 
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            },
            compareScopes: async function (current) {
                const session_token = await getSessionToken(this.app);

                const query = `{
                currentAppInstallation{
                    id
                    accessScopes {
                        handle
                    }
                }
            }`

            try {
                await graphql({
                    data: {
                        graphql: {
                            query: query
                        }
                    }
                }).then(response => {
                    const scope = response.data.data.currentAppInstallation.accessScopes.map(scope => scope.handle).slice().sort();
                    if (JSON.stringify(scope) != JSON.stringify(current)) {
                        this.axios.post(`${this.env.api_server}/api/v3/auth/scopes.json`, {'scope': scope.join(',')}, {
                            headers: { 
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${session_token}`,
                                'App': process.env.VUE_APP_APP_NAME,
                            }
                            })
                    }
                });
                
            } catch (error) {
                console.log('err', error)
            }

            }
        },

        mounted: async function () {
            try {
                this.app = await this.$store.dispatch('Shopify/initAppBridge', this.$route.query.host)
                const shop = this.$route.query.shop
                const host = this.$route.query.host
                const session_token = await getSessionToken(this.app);
                this.axios.post(`${this.env.api_server}/api/v3/auth/validate.json`, {}, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session_token}`,
                    'App': process.env.VUE_APP_APP_NAME,
                    'Hosthash': host
                }
                }).then(response => {
                    if(response.data.success) {
                        this.$store.commit('Shopify/setCredentials', {
                            ... this.$route.query,
                            ... response.data,
                            token: response.data.data.token,
                        })
                        const current_scope = response.data.scope || ''
                        this.compareScopes(current_scope.split(',').slice().sort())
                        this.checkBillingStatus() 
                    }
                })
                .catch(error => {
                    console.log('error', error)
                })
                        
            } catch (e) {
                console.log('e', e)
            }
        },
        computed: {
            ...mapState(['env', 'Shopify']),
        }
    }
</script>