<template>
    <div></div>
</template>
  
  <script>
  import { mapState } from 'vuex'

  export default {
      name: 'BillingActivate',
      created: function () {
        if(this.$route.query.charge_id){
            const path = this.$route.path.split('/')
            this.axios.post(`${this.env.api_server}/api/billings/activate.json`, {app: path[3], shop: path[4], charge_id: this.$route.query.charge_id}, {
                headers: { 
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                if(response.data.billing && response.data.billing.status == 'active') {
                    this.$store.dispatch('Shopify/initAppBridge', response.data.host).then(result => {
			            this.$router.push('/init')
		            })
                }
            })
        }
    },
    computed: {
        ...mapState(['env'])
    }
  }
  </script>